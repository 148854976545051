import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SlideDefinitionListQuery } from '@models/slide-definition-filter';
import { SlideDefinition, SlideDefinitionType } from '@models/slide-definition';
import { ApiService } from '@storykit/ui-components';
import { Cws } from '@storykit/typings';

interface ListResponse {
  slideDefinitions: SlideDefinition[];
  totalCount: number;
  pageSize: number;
}

@Injectable({
  providedIn: 'root',
})
export class SlideDefinitionService {
  readonly baseUrl = `${environment.api.cws.endpoint}/videostudio/definition`;

  constructor(
    private httpClient: HttpClient,
    private apiService: ApiService
  ) {}

  getSlideDefinitions(
    slideDefinitionListQuery: Partial<SlideDefinitionListQuery> = {}
  ): Observable<ListResponse> {
    const params = this.getHttpParams(slideDefinitionListQuery);
    return this.httpClient
      .get<SlideDefinition[]>(`${this.baseUrl}s`, {
        params,
        observe: 'response',
      })
      .pipe(
        map((response) => {
          const slideDefinitions = response.body ?? [];

          return {
            slideDefinitions,
            totalCount: this.getTotalCount(response),
            pageSize:
              slideDefinitionListQuery.pageSize ?? slideDefinitions.length,
          };
        })
      );
  }

  getSlideDefinition(id: string): Observable<SlideDefinition> {
    return this.httpClient.get<SlideDefinition>(`${this.baseUrl}/${id}`);
  }

  getSlideDefinitionGroups(): Observable<
    Cws.GetAllGroupsAndDefinitions['response']
  > {
    return this.apiService
      .call<Cws.GetAllGroupsAndDefinitions>({
        origin: environment.api.cws.endpoint,
        path: '/videostudio/definition/group',
        query: {
          lean: true,
        },
        params: {},
        body: null,
        method: 'GET',
      })
      .pipe(map(({ body }) => body));
  }

  getSlideDefinitionTypes(): Observable<SlideDefinitionType[]> {
    return this.httpClient.get<SlideDefinitionType[]>(`${this.baseUrl}Type`);
  }

  createSlideDefinitionType(name: string): Observable<any> {
    return this.httpClient.post<any>(`${this.baseUrl}Type`, { name });
  }

  createSlideDefinition(
    slideDefinition: Omit<SlideDefinition, '_id'>
  ): Observable<SlideDefinition> {
    return this.httpClient.post<SlideDefinition>(this.baseUrl, slideDefinition);
  }

  updateSlideDefinition({
    _id,
    ...slideDefinition
  }: SlideDefinition): Observable<SlideDefinition> {
    return this.httpClient.put<SlideDefinition>(
      `${this.baseUrl}/${_id}`,
      slideDefinition
    );
  }

  deleteSlideDefinition(id: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.baseUrl}/${id}`);
  }

  private getHttpParams({
    pageIndex,
    pageSize,
    search,
    accessCategory,
    garboSceneLocation,
  }: Partial<SlideDefinitionListQuery> = {}) {
    let params = new HttpParams().appendAll({
      orderBy: 'displayName',
      order: 'asc',
      status: 'newest',
    });

    if (accessCategory !== undefined && accessCategory !== '') {
      params = params.set('accessCategory', String(accessCategory));
    }

    if (garboSceneLocation !== undefined) {
      params = params.set('garboSceneLocation', String(garboSceneLocation));
    }

    if (pageIndex !== undefined) {
      params = params.append('page', String(pageIndex));
    }

    if (pageSize !== undefined) {
      params = params.append('limit', String(pageSize));
    }

    if (search !== undefined && search !== '') {
      params = params.set('search', search);
    }

    return params;
  }

  private getTotalCount({ headers }: { headers: HttpHeaders }) {
    return headers.get('X-CWS-Total') ? Number(headers.get('X-CWS-Total')) : 0;
  }
}
