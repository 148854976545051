import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { ApiService } from '@storykit/ui-components';
import { Cws } from '@storykit/typings';
import { ClientsAgencies } from '@models/cws';
import { combineLatest, Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { ObservableCacheService } from './observable-cache.service';
import { UpdatedFonts } from '@models/font';
import {
  GetUsersResponse,
  SignupSource,
  VideoFont,
} from '@storykit/typings/src/cws';
import { User } from '@models/user';
import { UserListQuery } from '@models/user-filter';
import { HttpHeaders } from '@angular/common/http';

const PAGE_SIZE = 25;

@Injectable({
  providedIn: 'root',
})
export class CwsService {
  constructor(
    private api: ApiService,
    private cache: ObservableCacheService
  ) {}

  getAgencies(): Observable<kit.IAgency[]> {
    return this.api
      .call<Cws.ListAgencies>({
        origin: environment.api.cws.endpoint,
        path: '/agency',
        params: {},
        method: 'GET',
        query: null,
        body: null,
      })
      .pipe(map(({ body }) => body));
  }

  getClients(): Observable<kit.IClient[]> {
    return this.api
      .call<Cws.ListClients>({
        origin: environment.api.cws.endpoint,
        path: '/client',
        params: {},
        method: 'GET',
        query: {},
        body: null,
      })
      .pipe(map(({ body }) => body));
  }

  updateVideoSlideDefsCategory(definitionId: string, accessCategory: string) {
    return this.api
      .call<Cws.PutDefinition>({
        origin: environment.api.cws.endpoint,
        path: `/videostudio/definition/:definitionId`,
        params: { definitionId },
        method: 'PUT',
        query: null,
        body: { accessCategory },
      })
      .pipe(map(({ body }) => body));
  }

  getClientsAgencies(): Observable<ClientsAgencies> {
    return this.cache.get('clientsAgencies', this.httpGetClientsAgencies());
  }

  getClientsByAgencyId(agencyId: string): Observable<kit.IClient[]> {
    return this.getClientsAgencies().pipe(
      map((clientsAndAgency) =>
        clientsAndAgency.clients.filter(
          (client) => client.agency._id === agencyId
        )
      )
    );
  }

  updateFonts(font: UpdatedFonts[]) {
    return this.api
      .call<Cws.UpdateFont>({
        origin: environment.api.cws.endpoint,
        path: '/agency/font',
        params: {},
        method: 'PUT',
        query: null,
        body: font,
      })
      .pipe(map(({ body }) => body));
  }

  deleteFont(agencyId: string, fontId: string) {
    return this.api
      .call<Cws.DeleteFont>({
        origin: environment.api.cws.endpoint,
        path: `/agency/:agencyId/font/:fontId`,
        params: { agencyId, fontId },
        method: 'DELETE',
        query: {},
        body: null,
      })
      .pipe(map(({ body }) => body));
  }

  listFonts(agencyId: string): Observable<VideoFont[]> {
    return this.api
      .call<Cws.GetFonts>({
        origin: environment.api.cws.endpoint,
        path: `/videostudio/font`,
        params: {},
        method: 'GET',
        query: {
          agency: agencyId,
          global: 'false',
        },
        body: null,
      })
      .pipe(map(({ body }) => body));
  }

  getSignedFontUrl(
    agencyId: string,
    fontId: string
  ): Observable<{ url: string }> {
    return this.api
      .call<Cws.GetSignedFont>({
        origin: environment.api.cws.endpoint,
        path: '/agency/:agencyId/font/:fontId',
        params: { agencyId, fontId },
        query: undefined,
        method: 'GET',
        body: {},
      })
      .pipe(map(({ body }) => body));
  }

  setBlocked(userId: string, blocked: boolean) {
    return this.api
      .call<Cws.SetBlockedUser>({
        origin: environment.api.cws.endpoint,
        path: '/user/:userId/setBlocked',
        params: { userId },
        method: 'PUT',
        query: null,
        body: { blocked },
      })
      .pipe(map(({ body }) => body));
  }

  removeDeletedStatus(userId: string) {
    return this.api
      .call<Cws.RemoveDeletedStatus>({
        origin: environment.api.cws.endpoint,
        path: '/admin/users/:userId/remove-deleted-status',
        params: { userId },
        method: 'PUT',
        query: null,
        body: null,
      })
      .pipe(map(({ body }) => body));
  }

  getPasswordResetLink(userId: string) {
    return this.api
      .call<Cws.GetPasswordResetLink>({
        origin: environment.api.cws.endpoint,
        path: '/admin/users/:userId/passwordreset',
        params: { userId },
        method: 'GET',
        query: null,
        body: null,
      })
      .pipe(map(({ body }) => body));
  }

  checkUserIpBlock(userId: string) {
    return this.api
      .call<Cws.GetBlockedIp>({
        origin: environment.api.cws.endpoint,
        path: '/admin/users/:userId/ip-block',
        params: { userId },
        method: 'GET',
        query: null,
        body: null,
      })
      .pipe(map(({ body }) => body));
  }

  removeUserIpBlock(userId: string) {
    return this.api
      .call<Cws.RemoveBlockedIp>({
        origin: environment.api.cws.endpoint,
        path: '/admin/users/:userId/ip-block',
        params: { userId },
        method: 'DELETE',
        query: null,
        body: null,
      })
      .pipe(map(({ body }) => body));
  }

  resendInviteEmail(userId: string) {
    return this.api
      .call<Cws.ResendInviteEmail>({
        origin: environment.api.cws.endpoint,
        path: '/admin/users/:userId/resend-invite-email',
        params: { userId },
        method: 'GET',
        query: null,
        body: null,
      })
      .pipe(map(({ body }) => body));
  }

  updateUser(user: Cws.UpdateUserFromAdmin['body']): Observable<User> {
    return this.api
      .call<Cws.UpdateUserFromAdmin>({
        origin: environment.api.cws.endpoint,
        path: '/admin/users/:userId',
        params: { userId: user._id },
        method: 'PUT',
        query: null,
        body: user,
      })
      .pipe(map(({ body }) => body as User));
  }

  deleteUser(userId: string) {
    return this.api
      .call<Cws.AnonymizeUser>({
        origin: environment.api.cws.endpoint,
        path: '/admin/users/:userId',
        params: { userId },
        method: 'DELETE',
        query: null,
        body: null,
      })
      .pipe(map(({ body }) => body));
  }

  getUsers(
    userListQuery: Partial<UserListQuery> = {}
  ): Observable<GetUsersResponse> {
    return this.api
      .call<Cws.GetUsersAdmin>({
        origin: environment.api.cws.endpoint,
        path: '/admin/users',
        params: {},
        method: 'GET',
        query: this.getUsersSearchParams(userListQuery),
        body: null,
      })
      .pipe(
        map(
          (response): GetUsersResponse => ({
            // Assertion needed until all types are updated
            users: (response.body as User[]) || [],
            totalCount: this.getTotalCount(response),
            pageSize: PAGE_SIZE,
          })
        )
      );
  }

  getUser(userId: string): Observable<User> {
    return this.api
      .call<Cws.GetUserFromAdmin>({
        origin: environment.api.cws.endpoint,
        path: '/admin/user/:userId',
        params: { userId },
        method: 'GET',
        query: null,
        body: null,
        // Assertion needed until all types are updated
      })
      .pipe(map(({ body }) => body as User));
  }

  getCurrentUser(): Observable<User> {
    return this.api
      .call<Cws.GetCurrentUser>({
        origin: environment.api.cws.endpoint,
        path: '/user/current',
        params: {},
        method: 'GET',
        query: null,
        body: null,
        // Assertion needed until all types are updated
      })
      .pipe(map(({ body }) => body as User));
  }

  createUser(
    user: Omit<Cws.CreateUserFromAdmin['body'], 'signupSource'>
  ): Observable<User> {
    return this.api
      .call<Cws.CreateUserFromAdmin>({
        origin: environment.api.cws.endpoint,
        path: '/admin/users',
        params: {},
        method: 'POST',
        query: null,
        body: { ...user, signupSource: SignupSource.CRM },
        // Assertion needed until all types are updated
      })
      .pipe(map(({ body }) => body as User));
  }

  private httpGetClientsAgencies(): Observable<ClientsAgencies> {
    return combineLatest([this.getAgencies(), this.getClients()]).pipe(
      map(([agencies, clients]) => ({
        agencies,
        clients,
      })),
      shareReplay(1)
    );
  }

  private getUsersSearchParams({
    role,
    search,
    pageIndex,
    pageSize,
    accessIds,
    includeDeleted,
  }: Partial<UserListQuery> = {}): Cws.GetUsersAdmin['query'] {
    const httpParams: Cws.GetUsersAdmin['query'] = {};

    if (role) {
      httpParams.role = role;
    }

    if (search) {
      httpParams.search = search;
    }

    if (includeDeleted) {
      httpParams.include_deleted = includeDeleted;
    }

    if (typeof pageIndex !== 'undefined') {
      httpParams.page = pageIndex.toString();
    }

    if (typeof pageSize !== 'undefined') {
      httpParams.page_size = pageSize.toString();
    }

    const accessParams: any = {};
    if (accessIds && accessIds.length) {
      accessParams.access_ids = accessIds;
    }

    return { ...accessParams, ...httpParams };
  }

  private getTotalCount({ headers }: { headers: HttpHeaders }) {
    return headers.get('x-total') ? Number(headers.get('x-total')) : 0;
  }
}
