import { DataSource } from '@angular/cdk/collections';
import { Injectable } from '@angular/core';
import { User, UserRole } from '@models/user';
import { UserListQuery } from '@models/user-filter';
import { BehaviorSubject, combineLatest, concat, Observable } from 'rxjs';
import {
  tap,
  debounceTime,
  distinctUntilChanged,
  map,
  shareReplay,
  switchMap,
  take,
} from 'rxjs/operators';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { CwsService } from '@core/services/cws.service';
import { GetUsersResponse } from '@storykit/typings/src/cws';

type UserListQueryWithoutSearch = Omit<UserListQuery, 'search'>;

@Injectable()
export class UserListDataSource extends DataSource<User> {
  filters$: Observable<UserListQuery>;
  debouncedFilters$: Observable<[UserListQueryWithoutSearch, string, string[]]>;

  users$: Observable<GetUsersResponse>;

  private filterSubject = new BehaviorSubject<UserListQueryWithoutSearch>({
    pageIndex: 1,
    pageSize: 25,
    role: '',
  });

  private queryFilters$ = this.filterSubject
    .asObservable()
    .pipe(distinctUntilChanged<UserListQueryWithoutSearch>(this.isSame));

  private searchSubject = new BehaviorSubject<string>('');
  private search$ = this.searchSubject.asObservable();

  private accessIdsSubject = new BehaviorSubject<string[]>([]);
  private accessIds$ = this.accessIdsSubject.asObservable();

  constructor(private cwsService: CwsService) {
    super();

    this.filters$ = combineLatest([this.queryFilters$, this.search$]).pipe(
      map(([filters, search]) => ({ ...filters, search }))
    );

    this.debouncedFilters$ = combineLatest([
      this.queryFilters$,
      this.debounceAllButFirst(this.search$).pipe(
        tap(() => this.resetPageIndex())
      ),
      this.accessIds$,
    ]);

    this.users$ = this.debouncedFilters$.pipe(
      switchMap(([filters, search, accessIds]) =>
        this.cwsService.getUsers({
          ...filters,
          search: search || undefined,
          accessIds,
        })
      ),
      shareReplay(1)
    );
  }

  connect(): Observable<User[]> {
    return this.users$.pipe(map(({ users }) => users));
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  disconnect(): void {}

  updateSearch({ search }: { search: string }) {
    this.searchSubject.next(search);
  }

  updateAccessIds(accessIds: string[]) {
    this.accessIdsSubject.next(accessIds);
  }

  updateRole(role: UserRole | '') {
    this.filterSubject.next({
      ...this.filterSubject.getValue(),
      pageIndex: 1,
      role,
    });
  }

  includeDeleted(event: MatCheckboxChange) {
    this.filterSubject.next({
      ...this.filterSubject.getValue(),
      pageIndex: 1,
      includeDeleted: event.checked,
    });
  }

  updatePage(pageEvent: { pageIndex: number; pageSize: number }): void {
    const { pageIndex, pageSize } = pageEvent;

    this.filterSubject.next({
      ...this.filterSubject.getValue(),
      pageIndex: pageIndex + 1,
      pageSize,
    });
  }

  private resetPageIndex() {
    this.filterSubject.next({
      ...this.filterSubject.getValue(),
      pageIndex: 1,
    });
  }

  private isSame(a: Record<string, any>, b: Record<string, any>) {
    const keys = [...Object.keys(a), ...Object.keys(b)];

    return keys.every((key) => a[key] === b[key]);
  }

  private debounceAllButFirst<T>(inputStream$: Observable<T>) {
    return concat(
      inputStream$.pipe(take(1)),
      inputStream$.pipe(debounceTime(800))
    ).pipe(distinctUntilChanged());
  }
}
